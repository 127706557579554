.questin-Listing{
    border-radius : 5px;
    box-shadow : 0px 0px 3px 0px silver;
    padding : 10px  20px;
}
.questin-Listing ul li{
    list-style : none;
    /* display : inline-block; */
}
.pcf-list{
  height: 188px;
  z-index: 99;
  background: white;                  
  position: absolute;
  width : auto
}
.scroll-list{
  overflow-y: scroll
}
.selected-pcf{
  border: 1px solid silver;
  padding: 2px 7px;
  border-radius: 5px;                  
  background: white; 
  margin-right: 5px              
}
.pcf-parent{                  
  display: inline-block
}
.show_option li{
  display : inline-block;
  padding-right: 3px;
}
.show-tags{                                       
  position: absolute;
  top: 34px;
  left: 21px; 
}
.keyword-span{
border: 1px solid silver;
padding: 4px 7px;
  border-radius: 5px;
  margin-right:5px;
}
.coll-span{
  position: absolute;
  top: 40px;
  left: 21px; 
  background: white;
}
.selection-tags{
  border: 1px solid #d2ddec;
  border-radius: .375rem;
  height: calc(1.5em + 1rem + 2px);
}
.key-input{
  border : none !important;
  outline: none !important;
}

.show-action{
  position: absolute; 
  will-change: transform; 
  top: -34px; 
  /* left: -43px;  */
  left: 114px;
  transform: translate3d(-86px, 29px, 0px);
  text-align : center;
  z-index: 99;
  background: white;
}

.show_option li{
  display : inline-block,
}

.error{
  color: red;
}
.error-show{
  border: 1px solid red;
}


ul.pagination li {
  padding: 10px;
  cursor: pointer;
}
.pagination{
  justify-content: center;
}
.pagination a{
  color: black;
}
.pagination .active{
  border-bottom:1px solid #1657af;
}
.pagination .disabled a{
  color: gray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.checked {
  color: orange;
}

.react-datepicker-wrapper{
  display : block !important;
}
.react-datepicker__triangle{
  left : 50px !important
}

.dropdown-List{
  box-shadow : 0px 0px 3px 0px silver;
  border-radius : 5px;
  color : gray;
  list-style : none;
  position: absolute;
  background: white;
  z-index: 99;
  width: 15%;
}
.dropdown-List li{
  padding-top : 5px;
  padding-bottom : 5px;
}
.data-span{
  border: 1px solid silver;
  border-radius: 5px;
  padding: 2px 8px;
}

/* .header-tabs li.nav-item {
  white-space: nowrap;
} */

.tag .badge-soft-light {
  color:#6e84a3;
  background-color: #e2e6ed;
}

.tag .badge {
 
  padding: 10px;
  font-size: 100%;
  margin-right: 6px;
  margin-bottom: 6px;
}

.user-details span {
  display: block;
  color:#95aac9;
}

.tag {
  margin-top: 15px;
}

.des-scroll{
  max-height: 100px;
    overflow-y: auto;
}
.thumbnail-div{
  height: 80px;
  width: 100px;
}

.nav-icon {
  position: absolute;
  top: 47%;
  transform: translate(-50%, -50%);
  transition: all .5s ease-in-out;
}

.nav-left.nav-icon{
  left: -23px; background: rgba(0,0,0,.5);border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
}

.nav-right.nav-icon {
  right: -15%;
  background: rgba(0,0,0,.5);
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
}

.nav-right span.fe.fe-chevron-right {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  line-height: unset;
}

.nav-left span.fe.fe-chevron-left {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  line-height: unset;
}

.carasoul-image {
    text-align: center;
    height: 443px;
    max-width: 590px;
    margin: 30px auto 15px;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    background: #fff;
}

.project_big_image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 443px;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 12px;
}

.carasoul-image:hover .nav-right{ 
  right: -5%;
}

.carasoul-image:hover .nav-left{ 
  left: 2.5%;
}

.comp-ques{
  border: 1px solid silver;
  border-radius : 5px;
  text-align : center
}
.active-quse{
  color: #2870e5;
    border-color: #2870e5;
}

button.close.media-upload-modal {
  position: absolute;
  right: 15px;
  z-index: 999;
}

.overflow-content.user-work-content {
  max-height: 800px;
  background-color: #fff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-y: auto;
}

.overflow-content::-webkit-scrollbar {
  width: 5px;
}
.overflow-content::-webkit-scrollbar-thumb {
  background: #b7babc;
}
.overflow-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dflex {
  display: flex!important;
  align-items: center;
  justify-content: space-between;
}

.font-weight-500 {
  font-weight: 600!important;
}
.font-12 {
  font-size: 12px!important;
}
.text-sub {
  color: #6f7d91!important;
}

.font-20 {
  font-size: 20px!important;
}

.text-dark-black {
  color: #000!important;
}

.font-13 {
  font-size: 13px!important;
}

.text-third {
  color: #707d91!important;
}

.letter-spacing-015 {
  letter-spacing: -.15px!important;
}

.font-16 {
  font-size: 16px!important;
}

.font-weight-600, .font-weight-700 {
  font-weight: 700!important;
}

.w-60 {
  width: 60%!important;
}

.w-40 {
  width: 40%!important;
}
.card-new{
  border-color: #ebe4e4 !important
}

/* ul.nav.nav-tabs.nav-overflow.header-tabs {
  white-space: nowrap;
} */

/* .custom-scroll::-webkit-scrollbar {
  display: block; width:4px !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
/* .custom-scroll:-webkit-scrollbar-thumb {
  background: #888; 
} */

/* .custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #95aac9;
}   */

/* .nav-overflow::-webkit-scrollbar{display:none} */

.react-tel-input .form-control {
  display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem .75rem;
    padding-left: 48px;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.error-show {
  border: 1px solid red !important;
}

.selected-option{
  border: 1px solid #10c89b !important;
}
.disable-input{
  background-color: silver !important;
  opacity: 0.5 !important;
}
.custom-modal{
  max-height: 56vh;
  overflow-y: auto;
}

.cu-67 {
  position: relative;
}

.cu-67 p {
  position: absolute;
  margin: 0;
  top: calc(50% + 15px);
  transform: translateY(-50%);
  right: 20px;
}

.cu-67  .error-show + p{
  top: calc(50% + 5px);
}

/* Approvals modal CSS */
.user-work-section{
  text-align: center;
  height: 443px;
  max-width: 590px;
  margin: 0px auto 15px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  background: #f5f7fd;
}
.user-work-section > .image_preview_show{
  height:100%;
}
.user-work-section .image_preview_show img {
  height:100%;
  object-fit: contain;
}
.user-work-thumbnail{
  padding: 15px 5px 15px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap; 
}
.user-work-thumbnail .thumbnail-div{
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  border-radius: 12px;
  margin-right: 10px;
  width: 100px;
  height: 75px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  cursor:pointer;
}  
.creative-field.creative_wrapper .container-custom, 
.creative-field.creative_wrapper .container-custom{
  padding-left:0px !important;
  padding-right:0px !important;
  width:100%;
} 


/* Star CSS */
/* component */

.stars-rating {
  /* border:solid 1px #ccc; */
  display:flex;
  flex-direction: row-reverse;
  font-size:2em;
  justify-content:space-around;
  padding:0 .2em;
  text-align:center;
  width:1em; 
}

.stars-rating input {
  display:none;
}

.stars-rating label {
  color:#ccc;
  cursor:pointer;
}

/* .stars-rating.active :checked ~ label {
  color:#f90;
} */
.stars-rating.active label {
  color:#f90;
}

/* .stars-rating label:hover,
.stars-rating label:hover ~ label {
  color:#fc0;
} */


.specialisation_block{
  border: 1px solid #d2ddec;
  border-radius: .375rem;
  padding:5px;
  margin-left:1px;
  margin-right:1px;
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

/* upload css */
.uploading-image.review_milestone_uploading .upload-thumbnail {
  -webkit-display: grid;
    -moz-display: grid;
    -ms-display: grid;
    -o-display: grid;
    display: grid;
    list-style: none;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-gap: 2px 5px;
}
.uploading-image.review_milestone_uploading .upload-thumbnail .upload-thumb {
    display: inline-block;
    border: 1.5px solid #d7d3d3;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 15px;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    position: relative;
    cursor: pointer;
}
.upload_image_thumbnail {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 65px;
    vertical-align: middle;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
}
/* Nischay Css */
.card-header .text_sm{
  font-size: 0.9rem;
}

.cm-toggle {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  border: 0;
  outline: 0;
  cursor: pointer;
}

/* To create surface of toggle button */
.cm-toggle:after {
  content: '';
  width: 55px;
  height: 22px;
  display: inline-block;
  background: rgba(196, 195, 195, 0.55);
  border-radius: 18px;
  clear: both;
}
.cm-toggle-sm:after {
  content: '';
  width: 50px;
  height: 20px;
  display: inline-block;
  background: rgba(196, 195, 195, 0.55);
  border-radius: 18px;
  clear: both;
}

/* Contents before checkbox to create toggle handle */
.cm-toggle:before {
  content: '';
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  left: 0;
  top: -3px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 6px rgba(10, 10, 10, 0.6);
}
.cm-toggle-sm:before {
  content: '';
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  left: 0;
  top: -3px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 6px rgba(10, 10, 10, 0.6);
}


/* Shift the handle to left on check event */
.cm-toggle:checked:before {
  left: 28px;
  box-shadow: -1px 1px 6px rgb(10 10 10 / 60%);
}
/* Background color when toggle button will be active */
.cm-toggle:checked:after {
  background: #387eff;
}
